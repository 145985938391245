import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import AstroSearch from "./components/AstroSearch";

import TalkAstroSearch from "./components/TalkAstroSearch";
import OnlinePuja from "./components/OnlinePuja";
import FamousAstrologer from "./components/FamousAstrologer";
import ChatAstroSearch from "./components/ChatAstroSearch";
import DailyFileSearch from "./components/DailyFileSearch";
import DailyFeedFile from "./components/DailyFeedFile.js";
import DailyFeedSearch from "./components/DailyFeedSearch.js"
import AstrologerCardSearch from "./components/AstrologerCardSearch";
import MonthlyFileSearch from "./components/MonthlyFileSearch";
import ChatAstroSearch1 from "./components/ChatAstroSearch1";
import DailyRashiSearch from "./components/DailyRashiSearch";
import Footer from "./components/Footer";
import WhyAstrology from "./components/WhyAstrology";
import DashboardLinkButton from "./components/DashboardLinkBtn";
import HoroscopeReport from "./components/HoroscopeReport";
import RashiDetails from "./components/RashiDetails.js";

import Wallet from "./components/Wallet";
import PaymentCallback from "./components/PaymentCallback";
import ContactDetail from "./components/ContactDetail";
import KundliMatch from "./components/KundliMatch";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import Disclaimer from "./components/Disclaimer";
import DailyHoroscope from "./components/DailyHoroscope";
import FamousCard from "./components/FamousCard";
import PujaSevash from "./components/PujaSevash.js";
import DailyPanchang from "./components/DailyPanchang.js";
import AskQuestion from "./components/AskQuestion.js";
import AcharyaPrem from "./components/AcharyaPrem.js";

import BrahaspatiGraha from "./components/BrahaspatiGraha.js";
import MohiniPuja from "./components/MohiniPuja.js";
import VishkanthDosh from "./components/VishkanthDosh.js";
import AsadhaPuja from "./components/AsadhaPuja.js";
import GanthBandhan from "./components/GanthBandhan.js";
import MangalGraha from "./components/MangalGraha.js";
import ChandraGraha from "./components/ChandraGraha.js";
import ShukraGraha from "./components/ShukraGraha.js";
import Kalikkal from "./components/Kalikkal.js";
import NavgrahaShanti from "./components/NavgrahaShanti.js";
import ParivarShanti from "./components/ParivarShanti.js";
import GunDosh from "./components/GunDosh.js";
import SuryaGraha from "./components/SuryaGraha.js"

import ResetPassword from "./components/ResetPassword.js";
import UserProfile from "./components/UserProfile.js";
import OrderHistory from "./components/OrderHistory.js";
import PendingOrder from "./components/PendingOrder.js";
import HoroscopeDetails from "./components/HoroscopeDetails.js";
import Yearlyhoroscope from "./components/yearlyhoroscope.js";
import ChoosePandit from "./components/ChoosePandit.js";

import ForgotPassword from "./components/ForgotPassword.js";
import { Provider } from 'react-redux';
import store from '../src/Redux/store';
import HoroscopeSearch from "./components/HoroscopeSearch.js";

function App() {

  return (
    <>
     <Provider store={store}>
       <BrowserRouter>
        <Header />
        <DashboardLinkButton />
          <>
        <Routes>
        
          <Route path="/" element={<AstroSearch />} />
         
          <Route path="/AstrologerCardSearch" element={<AstrologerCardSearch />} />
          
          <Route path="/ChatAstroSearch1" element={<ChatAstroSearch1 />} />
          <Route path="/DailyRashiSearch" element={<DailyRashiSearch />} />
          <Route path="/Wallet" element={<Wallet />} />
          <Route path="/PaymentCallback" element={<PaymentCallback />} />
          <Route path="/ContactDetail" element={<ContactDetail/>} />
          <Route path="/HoroscopeReport" element={<HoroscopeReport/>} />
          <Route path="/KundliMatch" element={<KundliMatch/>} />
          <Route path ="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path = "/TermsAndConditions" element={<TermsAndConditions/>} />
          <Route path="/Disclaimer" element={<Disclaimer />} />
          <Route path="/DailyHoroscope" element={<DailyHoroscope />} />
         
          <Route path="/online-puja" element={<FamousCard />} />
          <Route path="/Puja-Seva" element={< PujaSevash/>} />
          <Route path="/Daily-Panchang" element={< DailyPanchang />} />
          <Route path="/Ask-Question" element={< AskQuestion />} />
         
          <Route path="/brahaspati-graha" element={<BrahaspatiGraha />} />
          <Route path="/Mohini-Puja" element={<MohiniPuja />} />
          <Route path="/Vishkanthak-Dosh" element={<VishkanthDosh />} />
          <Route path="/Asadha-Puja" element={<AsadhaPuja />} />
          <Route path="/Gath-Bandhan-Puja" element={<GanthBandhan />} />
          <Route path="/Mangal-Graha-Shanti-Puja" element={<MangalGraha />} />
          <Route path="/Chandra-Graha-Shanti-Puja" element ={<ChandraGraha />} />
          <Route path="/Shukra-Graha-Shanti-puja" element={<ShukraGraha />} />
          <Route path="/Kalik-Kaalsarp-Dosh" element={<Kalikkal />} />
          <Route path="/Navgraha-Shanti-Puja" element={<NavgrahaShanti />} />
          <Route path="/Parivar-Shanti" element={<ParivarShanti />} />
          <Route path="/Gun-Dosh-Puja" element={<GunDosh />} />
          <Route path="/Surya-Graha-Shanti-Puja" element={<SuryaGraha />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/UserProfile" element={<UserProfile />} />
          <Route path="/OrderHistory" element={<OrderHistory />} />
          <Route path="/PendingOrder" element={<PendingOrder />} />
          <Route path="/HoroscopeDetails" element={<HoroscopeDetails />} />
          <Route path="/daily-horoscope" element={<RashiDetails />} />

          <Route path="/ChoosePandit" element={<ChoosePandit />} />
          <Route path="/yearly-horoscope" element={<Yearlyhoroscope />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          {/* <Route path="/DailyFeedFile" element ={<DailyFeedFile />} /> */}
        </Routes>
        <Routes>
          <Route path="/" element={<FamousAstrologer />} />
        </Routes>
          {/* <Routes>
            <Route path="/" element={<OnlinePuja />} />
          </Routes> */}
        <Routes>
          <Route path="/" element={<TalkAstroSearch />} />
        </Routes>
        <Routes>
          <Route path="/" element={<HoroscopeSearch />} />
        </Routes>
        {/* <Routes>
          <Route path="/" element={<FamousAstrologer />} />
        </Routes> */}
        <Routes>
            <Route path="/" element={<OnlinePuja />} />
          </Routes>
        <Routes>
          <Route path="/" element={<WhyAstrology />} />
        </Routes>
        <Routes>
          <Route path="/" element={<ChatAstroSearch />} />
        </Routes>
        
        <Routes>
          <Route path="/" element={<DailyFileSearch />} />
        </Routes> 
        {/* <Routes>
          <Route path="/" element={<DailyFeedSearch />} />
        </Routes>  */}
        <Footer />
        </>
      </ BrowserRouter >
      </Provider>
    </>
  );
};
export default App;







// import AcharyaVishnu from "./components/AcharyaVishnu";
// import AcharyaKant from "./components/AcharyaKant";
// import CardDataSearch2 from "./components/CardDataSearch2";
// import HoroscopeSearch from "./components/HoroscopeSearch";
// import DailyFeedSearch from "./components/DailyFeedSearch";
// import AcharyaKedar from "./components/AcharyaKedar.js";

 {/* <Route path="/:astrologerName" element={<AcharyaPrem />} /> */}
        
           {/* <Route path="/AcharyaKedar" element={<AcharyaKedar/>} /> */}
          
          {/* <Route path="/:astrologerName" element={<AcharyaAmit />} /> */}

// import AcharyaJai from "./components/AcharyaJai.js";
// import AcharyaShubham from "./components/AcharyaShubham.js";
{/* <Route path="/AcharyaJai" element={<AcharyaJai />} /> */}
          {/* <Route path="/:astrologerName" element={<AcharyaJai />} /> */}
          {/* <Route path="/AcharyaShubham" element={<AcharyaShubham />} /> */}


           {/* <Routes>
          <Route path="/" element={<CardDataSearch2 />} />
        </Routes> */}

 {/* <Routes>
          <Route path="/" element={<DailyFeedSearch />} />
        </Routes> */}

// import ShaniGraha from "./components/ShaniGraha.js";
// import RahuGraha from "./components/RahuGraha.js";
// import Relashionship from "./components/Relashionship.js";

{/* <Route path="/ShaniGraha" element={<ShaniGraha />} />
<Route path="/RahuGraha" element={<RahuGraha />} />
<Route path="/Relashionship" element={<Relashionship />} /> */}

 {/* <Route path="/Reports" element={<MonthlyFileSearch showHide={showHide}/>} /> */}

 {/* <Route path="AcharyaVishnu" element={<AcharyaVishnu/>} /> */}
