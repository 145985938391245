import React, { useState } from 'react';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import { Button } from "@mui/material";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
const Wallet = () => {
    const navigate = useNavigate();
    const [selectedAmount, setSelectedAmount] = useState("");
    const [selectedCardAmount, setSelectedCardAmount] = useState(null);
    const [walletAmount, setWalletAmount] = useState(() => {
        const storedAmount = localStorage.getItem('totalWalletAmount');
        return storedAmount ? Number(storedAmount) : 0;
    });
    const user_id = localStorage.getItem('user_id');
    const handleAmountSelection = (amount) => {
        setSelectedAmount(amount);
        setSelectedCardAmount(amount);
    };

    const handlePayment = async () => {
        if (!selectedAmount || isNaN(selectedAmount) || Number(selectedAmount) <= 0) {
            alert('Please enter a valid amount');
            return;
        }
        // alert(user_id,'user_id');
        try {
            // Request order from backend
            // const orderResponse = await axios.post('https://app.astrovedaguru.com/api/create-order', {
            const orderResponse = await axios.post('https://app.astrovedaguru.com/api/create-order-payment', {
                receipt: 'order_rcptid_11',
                amount: selectedAmount*100,  // Convert amount to paise
                user_id: user_id  // Convert amount to paise
            });
            const { order_id, key_id, amount } = orderResponse.data;
            const options = {
                "key": key_id,
                "amount": amount, 
                "currency": "INR",
                "name": "Astrovedaguru",
                "description": "Purchase Description",
                "image": "https://astrovedaguru.com/static/media/avg-logo.02bd90076148acc059e4f6c3736cd706.jpeg",
                "handler": async function (response) {
                    try {
                        // alert('sss');
                        // Verify payment with backend
                        const paymentResponse = await axios.post(
                            'https://app.astrovedaguru.com/api/addpayment',
                            {
                                amount: selectedAmount,
                                paymentMode: 'Phone-Pay',
                                paymentReference: '1223232323',
                                paymentStatus: 'Success',
                                signature: 'test',
                                orderId: '123456'
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${localStorage.getItem("token")}`
                                }
                            }
                        );



                       const updatedWalletAmount= Number(localStorage.getItem('totalWalletAmount'))+Number(selectedAmount);
                        localStorage.setItem("totalWalletAmount", updatedWalletAmount);
                        console.log("login amount", updatedWalletAmount)
                        setWalletAmount(updatedWalletAmount);
                        alert(paymentResponse.data.message);
                    } catch (error) {
                        console.error('Payment verification error', error);
                        alert('Payment verification failed');
                    }
                },
                "prefill": {
                    "name": "John Doe",
                    "email": "john.doe@example.com",
                    "contact": "9999999999"
                },
                "theme": {
                    "color": "#3399cc"
                }
            };

            // const paymentObject = new window.Razorpay(options);
            // paymentObject.open();
            window.location.href = orderResponse.data.payment_url;
        } catch (error) {
            console.error('Payment error', error);
            alert('Failed to initiate payment');
        }
    };

    return (
        <>
            <div className='main-contact'>
                <div className='acharya-ajay'>
                    <Typography variant='h6' fontWeight="700" sx={{ alignItems: "center", padding: "10px" }}>
                        <ArrowBackSharpIcon fontWeight="bold" sx={{ fontSize: "20px", marginRight: "10px", alignItems: "center" }} onClick={() => { navigate("/") }} />
                        Wallet
                    </Typography>
                </div>
                <div className='container mt-3'>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-1">
                            <div className="card card16">
                                <h6>Wallet Balance</h6>
                            </div>
                            <div className="promotional">
                                <div className="balance">
                                    <h6>Promotional Balance</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-8 col-sm-12">
                            <div className="card card18">
                                <br />
                                <div className='wallet-container'>
                                    <h5>Available Wallet Balance</h5>
                                    <h4><CurrencyRupeeIcon sx={{ fontSize: "20px", fontWeight: "600" }} />{walletAmount}</h4>
                                </div>

                                <div className='row'>
                                    <div className='col-2'></div>
                                    <div className='col-6'>
                                        <p className='add' style={{ margin: "0px" }}>Add Money to Wallet</p>
                                    </div>
                                    <div className='col-3'></div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col-2'></div>
                                    <div className='col-8'>
                                        <input 
                                            value={selectedAmount}
                                            onChange={(e) => setSelectedAmount(e.target.value)} 
                                            type="text" 
                                            placeholder='Enter money' 
                                            className='form-control'
                                        />
                                    </div>
                                    <div className='col-2'></div>
                                </div>

                                <div className='row mt-3'>
                                    <div className='col-2'></div>
                                    <div className='col-8'>
                                        <div className='row'>
                                            {[5,99, 500, 1000, 2000, 5000].map((amount) => (
                                                <div className='col-6' key={amount}>
                                                    <div 
                                                        className={`card pay-rupee ${selectedCardAmount === amount ? 'selected' : ''}`} 
                                                        onClick={() => handleAmountSelection(amount)}
                                                    >
                                                        <p style={{ margin: "inherit", fontWeight: "bold" }} className="wallet-paise">
                                                            <CurrencyRupeeIcon sx={{ fontSize: "18px", marginTop: "-3px" }} />
                                                            {amount}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className='col-2'></div>
                                </div>
                                <div className='row'>
                                    <div className='col-2'></div>
                                    <div className='col-8'>
                                        <div className='proceed'>
                                        <Button className="proceed-1" sx={{ color: "white", fontWeight:"700" }} onClick={handlePayment}>PROCEED TO PAY</Button>
                                        </div>
                                    </div>
                                    <div className='col-2'></div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Wallet;

