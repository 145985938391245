import React from "react";
 
 
export default function DashboardLinkBtn() {
  return (
    <div>
      <div className="dashborad-link-btn">
         <button className="dashboard-btn btn1"><a href="https://api.whatsapp.com/send?phone=919115536903"><i class="fa fa-brands fa-whatsapp"></i> </a></button>
         <button className="dashboard-btn btn2"><a href="https://www.instagram.com/astrovedaguru/"><i class="fa fa-brands fa-instagram"></i></a></button>
         <button className="dashboard-btn btn3"><a href="https://www.facebook.com/profile.php?id=61559725447792"><i class="fa fa-brands fa-facebook"></i></a> </button>
         <button className="dashboard-btn btn4"><a href="https://www.youtube.com/@ASTROVEDAGURU"><i class="fa fa-brands fa-youtube"></i></a></button>
         <button className="dashboard-btn btn5"><i class="fa fa-brands fa-linkedin"></i></button>
      </div>
    </div>
  );
}